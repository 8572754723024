<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="onHitBottom(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end justify-content-between py-0 my-0 mx-0">
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.date')"
                      :model.sync="filters.date"
                      name="invoice_date"
                      title="general.date">
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-4 my-lg-4 col-md-4 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-6 pr-0">
                  <button type="button" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="onHitBottom(null)">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-6 pr-0">
                  <button type="button" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
           <div class="px-5 py-3 min-height-30">
              <data-table
                  :bordered="false"
                  :borderless="true"
                  :fields="tableFields"
                  :hover="false"
                  :items="formattedItems"
                  :no-border-collapse="true"
                  :outlined="false"
                  :striped="false"
                  tableVariant="''"
                  @rowClicked="onRowClicked"
                  :is-busy="loadingPage"
              ></data-table>
            </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import baseModule,
{
  ITEMS_CURRENCY_RATES,
  CURRENCY_RATES_URL,
  LOADING_CURRENCY_RATE as LOADING,
  FILTER_CURRENCY as FILTER,
  SET_FILTER_CURRENCY as SET_FILTER, GET_CURRENCY_RATES, MODULE_NAME, ERROR, SUCCESS
} from "@/core/services/store/currency/currency.module";
import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import store from "@/core/services/store";
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "CurrencyRateIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule)
  },
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    DataTable,
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loadingPage() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + '/' + ITEMS_CURRENCY_RATES];
    },
    filter() {
      return store.getters[_MODULE_NAME + '/' + FILTER];
    },
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    formattedItems() {
      let results = [];
      if (!this.items || this.items.data === null || this.items.data === undefined) return results;
      let items = _.values(this.items.data);
      items.forEach((item, index) => {
        results.push({
          'code': item.code,
          'name': item.name,
          'rate': item.rate || 1,
          'buying': item.buying || 1
        });
      })
      return items;
    },
    tableFields() {
      return [
        {
          'key': 'code',
          'label': this.$t('invoice.currency_code'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
        },
        {
          'key': 'name',
          'label': this.$t('invoice.currency_name'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
        },
        {
          'key': 'rate',
          'label': this.$t('invoice.currency_buying'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
        },
        {
          'key': 'buying',
          'label': this.$t('invoice.currency_selling'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
        }
      ];
    }
  },
  data() {
    return {
      page: 1,
      perPage: 25,
    };
  },
  methods: {
    onRowClicked(item,index,event){
      this.$emit('exchange',item,index,event)
    },
    setFilter(filter) {
      this.$store.commit(_MODULE_NAME + '/' + SET_FILTER, filter);
    },
    openCreateNewInvoice() {
      this.$router.push({name: 'invoice.create'})
    },
    onHitBottom($state = null) {
      let self = this;
      if (self.isUserGranted('Invoice', ['viewAny'])) {
        let filters = _.cloneDeep(this.filters);

        if (filters) {
          this.$set(filters, 'date', moment(this.filters.date).format(LARAVEL_DATE_FORMAT));
        }

        self.$store.dispatch(_MODULE_NAME + '/' + GET_CURRENCY_RATES, {
          url: CURRENCY_RATES_URL,
          filters: filters,
        });
      }
    },
    resetFilters() {
      this.setFilter({
        date: moment().format(LARAVEL_DATE_FORMAT),
      })
    }
  },
  mounted() {
    if (this.isUserGranted('Invoice', ['viewAny'])) {
      if (!this.filter) {
        this.resetFilters();
      }

      if (!this.formattedItems.length) {
        this.onHitBottom()
      }
    }
  }
}
</script>

<style scoped>

</style>
